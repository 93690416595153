import { Button, Picto } from "@zolteam/react-ras-library";
import React from "react";

export interface IPayslip {
	id: string;
	title: string;
	path?: string;
	infos?: string;
	isRead?: boolean;
}

const ListLayoutItem: React.FC<IPayslip> = (props) => {
	const { title, infos, path, isRead = true } = props;

	return (
		<>
			<div className="flex flex-col sm:flex-row gap-0 sm:gap-m">
				<div className="min-w-[20vw]">
					<span className="flex items-center gap-s">
						{!isRead && (
							<Picto icon="circle" className="text-accent-500" />
						)}
						<p className="font-bold capitalize">{title}</p>
					</span>
				</div>
				<div className="">{infos}</div>
			</div>
			<div className="text-end">
				<Button
					type="button"
					color={"transparent"}
					className="ml-auto"
					disabled={!path}
				>
					<Picto
						icon={"eye"}
						className="[&>g]:!text-primary-500 !mr-0"
					/>
				</Button>
			</div>
		</>
	);
};

export default ListLayoutItem;
