import React from "react";
import { useFormikContext } from "formik";
import Field from "../../components/molecules/Field/Field";
import cn from "../../utils/cn";
import { t } from "i18next";
import {
	IPersonalInformationsCategory,
	IPersonalInformationsEditValues,
} from "./PersonalInformations";

const SocialSecurityInformations: React.FC<IPersonalInformationsCategory> = ({
	disabled,
}) => {
	const { values, setFieldValue } =
		useFormikContext<IPersonalInformationsEditValues>();

	return (
		<div className={cn([disabled && "cursor-not-allowed", "w-full"])}>
			<div className="relative flex flex-col items-start justify-start w-full gap-4">
				<div
					className={cn([
						"flex flex-col gap-2 w-full",
						disabled && "pointer-events-none opacity-70",
					])}
				>
					<b>{t("personalInfos.socialSecurityNumber.title")}</b>
					<div className="flex flex-col gap-2">
						<Field
							className="lg:w-[calc(50%-0.25rem)] xl:w-[calc(100%/3-0.5rem)]"
							label={t(
								"personalInfos.socialSecurityNumber.label"
							)}
							name="ssn"
							type="text"
							maxLength={15}
							value={values.ssn}
							onChange={(e) =>
								setFieldValue("ssn", e.target.value.trim())
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SocialSecurityInformations;
