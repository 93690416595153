import { DefaultTFuncReturn } from "i18next";
import React from "react";
import cn from "../../../utils/cn";
import SVG from "../../atoms/SVG/SVG";

interface IHomeTile {
	title?: string | DefaultTFuncReturn;
	text?: string | DefaultTFuncReturn;
	image?: React.ReactNode | any;
	imageClassName?: string;
	className?: string;
	onClick?: () => void;
	children?: React.ReactNode;
}

const HomeTile: React.FC<IHomeTile> = ({
	title,
	text,
	image,
	imageClassName = "",
	className,
	children,
	onClick,
}) => {
	const Img: any = image;

	// check if Img is a React component or a SVG
	const isSVG = (Img: any) => {
		if (!Img) return false;
		if (typeof Img === "string") return Img.includes("svg");
		return (
			Img && typeof Img === "function" && Img.prototype.isReactComponent
		);
	};

	const handleClick = () => {
		onClick && onClick();
	};

	const Tag = onClick ? "button" : "div";

	return (
		<div
			style={{
				flex: "1 1 48%",
			}}
			className={cn([
				"min-h-[150px] rounded-16 overflow-hidden bg-neutral-100 hover:bg-neutral-200 duration-500",
				className,
			])}
		>
			<Tag
				className="relative flex w-full h-full p-6 rounded-16"
				onClick={handleClick}
			>
				<div className="w-full text-left z-1 max-w-[80%]">
					<p className="mb-2 font-bold leading-5 text-heading-03">
						{title}
					</p>
					<p className="leading-5">{text}</p>
					{children}
				</div>
				{Img && (
					<div
						className={cn([
							"absolute tile-image z-0 right-0 -bottom-0 max-w-[50%]",
							imageClassName,
						])}
					>
						{isSVG(Img) ? (
							<SVG src={Img} className="w-full h-full" />
						) : (
							<Img className="absolute bottom-0 right-0" />
						)}
					</div>
				)}
			</Tag>
		</div>
	);
};

export default HomeTile;
