import React from "react";
import WorkCertificatesService from "../../services/WorkCertificatesService";
import { Button, InfoMessage, Select } from "@zolteam/react-ras-library";
import { Form, Formik } from "formik";
import { t } from "i18next";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../components/atoms/Spinner/Spinner";

import cn from "../../utils/cn";
import { TAllowedAgency } from "../../types";

export const GenerateWorkCertificateForm = ({ onSubmit, errorMessage }) => {
	const { isLoading, isFetching, data, error } = useQuery({
		queryFn: () => WorkCertificatesService.getAllowedAgencies(),
		queryKey: ["WorkCertificates", "agencies"],
		refetchOnWindowFocus: false,
		retry: false,
	});

	if (isLoading || isFetching) {
		return <Spinner />;
	}

	return (
		<Formik
			initialValues={{
				agency:
					data?.allowedAgencies?.length === 1
						? data.allowedAgencies[0]
						: null,
				period: null,
			}}
			onSubmit={onSubmit}
		>
			{({ values, setFieldValue, isSubmitting }) => {
				const isDisabled =
					isLoading || isFetching || isSubmitting || !!error;

				return (
					<Form
						className={cn([
							"flex flex-col gap-4",
							isLoading && "hidden",
						])}
					>
						<Select
							label={t("agencies.chooseYourAgency")}
							name="agency"
							value={values.agency}
							options={data?.allowedAgencies ?? []}
							onChange={(value: TAllowedAgency) =>
								setFieldValue("agency", value)
							}
							disabled={
								isDisabled ||
								data?.allowedAgencies?.length === 1
							}
							getOptionLabel={(option: TAllowedAgency) =>
								option.name
							}
							getOptionValue={(option: TAllowedAgency) =>
								option.id
							}
						/>

						{!isLoading && !data?.allowedAgencies?.length && (
							<InfoMessage withIcon color={"error"}>
								{t("workCertificates.noAgencies")}
							</InfoMessage>
						)}
						{!isLoading &&
							values.agency &&
							!values.agency?.lastAllowedDate && (
								<InfoMessage withIcon color={"error"}>
									{t("workCertificates.cantGenerate")}
								</InfoMessage>
							)}
						{!isLoading && errorMessage && (
							<InfoMessage withIcon color={"error"}>
								{errorMessage}
							</InfoMessage>
						)}
						<Button
							isLoading={isSubmitting}
							disabled={
								!values.agency ||
								!values.agency.lastAllowedDate ||
								isDisabled
							}
							color="primary"
							type="submit"
						>
							{isSubmitting
								? t("workCertificates.pendingGeneration")
								: t("workCertificates.generate")}
						</Button>
					</Form>
				);
			}}
		</Formik>
	);
};
