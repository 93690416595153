import { t } from "i18next";
import { toast } from "react-toastify";

export const promiseToast = (promise, messages?, options?: any) => {
	if (options?.toastId) {
		toast.update(options.toastId, {
			render: messages.pending || t("promiseToast.pending"),
			type: "default",
		});
	}
	return toast.promise(
		promise,
		{
			pending: t("promiseToast.pending"),
			success: t("promiseToast.success"),
			error: t("promiseToast.error"),
			...messages,
		},
		options
	);
};
