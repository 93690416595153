import React from "react";
import DocsLayout from "../../components/templates/DocsLayout/DocsLayout";
import { t } from "i18next";
import CVService from "../../services/CVService";
import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

export interface ICV {
	uuid: string;
	name: string;
	[other: string]: any;
}

const CV: React.FC = () => {
	const { state } = useLocation();
	const FirstSendRef = React.useRef(false);

	React.useEffect(() => {
		if (FirstSendRef.current) return;
		const getPreviousPageName = () => {
			const prevPage = JSON.parse(
				sessionStorage.getItem("prevPage") ?? "{}"
			);
			if (prevPage?.page?.name) return prevPage.page.name;
			if (prevPage?.parentRoute?.name) return prevPage.parentRoute.name;
			if (state?.pathname) return state.pathName;
			return t("profilePro.cv.title");
		};
		pushDataLayer({
			dataLayer: {
				event: "cv__voir_cv",
				previous_page: getPreviousPageName(),
			},
		});
		FirstSendRef.current = true;
	}, [state]);

	const fetchCV = () => CVService.getCV().then((res) => res.cvs);

	const handleDelete = (doc: any) => CVService.deleteCV(doc.uuid);

	const handleUpload = (files: any) => {
		pushDataLayer({
			dataLayer: {
				event: "cv__ajouter_cv",
			},
		});
		return CVService.uploadCV(files);
	};

	return (
		<DocsLayout
			title={t("profilePro.cv.title")}
			addTitle={t("profilePro.cv.uploadBtn")}
			query={{
				key: ["cvs"],
				fn: fetchCV,
			}}
			maxFiles={3}
			onUpload={handleUpload}
			onRemove={handleDelete}
			noDataMessage={<Trans i18nKey="profilePro.cv.empty" />}
			editable={false}
		/>
	);
};

export default CV;
