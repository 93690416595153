import React from "react";
import { Button, ModalV2, Text, InfoMessage } from "@zolteam/react-ras-library";
import { Form, Formik, useFormikContext } from "formik";
import Field from "../../components/molecules/Field/Field";
import cn from "../../utils/cn";
import { t } from "i18next";
import { promiseToast } from "../../toastify/toastify";
import {
	IPersonalInformationsCategory,
	IPersonalInformationsEditValues,
} from "./PersonalInformations";
import RegisterService from "../../services/RegisterService";
import SessionService from "../../services/SessionService";
import { Trans } from "react-i18next";
import CountDown from "../../components/atoms/CountDown/CountDown";
import Spinner from "../../components/atoms/Spinner/Spinner";
import { useQuery } from "@tanstack/react-query";
import ProfileService from "../../services/ProfileService";
import { toast } from "react-toastify";

const ContactInformations: React.FC<IPersonalInformationsCategory> = ({
	infos,
	disabled,
	refetchInfos,
}) => {
	const { values, setFieldValue, errors, validateForm, setFieldTouched } =
		useFormikContext<IPersonalInformationsEditValues>();

	const [sendingState, setSendingState] = React.useState({
		loading: false,
		error: "",
		type: "",
	});
	const user = SessionService.getUser();

	const sendVerificationCode = (type: string, value: string) => {
		if (errors.phoneNumber || errors.email) {
			setSendingState({
				loading: false,
				error:
					type === "phone"
						? t("personalInfos.contact.phoneError")
						: t("personalInfos.contact.emailError"),
				type: "",
			});
			return Promise.reject();
		}
		setSendingState({ loading: false, error: "", type: type });
		const prom = RegisterService.sendMediaSecurityToken({
			uniqueId: user.uniqueId,
			[type]: {
				value,
			},
		}).catch((err) => {
			setSendingState({
				...sendingState,
				loading: false,
				error: err.response.data.message,
			});

			throw err;
		});
		promiseToast(prom);
		return prom;
	};

	const { isLoading, data: contactVerification } = useQuery(
		["contactVerification"],
		() => {
			return ProfileService.getParams();
		}
	);
	if (isLoading)
		return (
			<>
				<b>{t("personalInfos.contact.title")}</b>
				<Spinner />
			</>
		);

	const hasPhoneNumber =
		!!values.phoneNumber &&
		values.phoneNumber === values.initialPhoneNumber;
	const hasEmail = !!values.email && values.email === values.initialEmail;

	return (
		<div className={cn([disabled && "cursor-not-allowed", "w-full"])}>
			<div className="relative flex flex-col items-start justify-start w-full gap-4 lg:w-[calc(50%-0.25rem)] xl:w-[calc(100%/3-0.5rem)]">
				<div
					className={cn([
						"flex flex-col gap-2 w-full",
						disabled && "pointer-events-none opacity-70",
					])}
				>
					<b className="min-w-[400px]">
						{t("personalInfos.contact.title")}
					</b>
					<div className="flex flex-col w-full gap-2 mb-4">
						<div className="flex flex-wrap xs:flex-nowrap w-full gap-2 [&>*]:w-full lg:[&>div]:min-w-full">
							<Field
								label={t("personalInfos.contact.phoneNumber")}
								name="phoneNumber"
								type="text"
								readOnly={hasPhoneNumber}
								disabled={hasPhoneNumber}
								maxLength={10}
							/>
							{values.phoneNumber &&
								values.phoneNumber ===
									values.initialPhoneNumber && (
									<Button
										color={
											hasPhoneNumber ||
											(values.phoneNumber &&
												!errors.phoneNumber)
												? "primary"
												: "grey"
										}
										type={"button"}
										onClick={() => {
											hasPhoneNumber
												? setFieldValue(
														"phoneNumber",
														""
												  )
												: setFieldValue(
														"phoneCheck",
														true
												  );
										}}
										className="!w-fit"
										size="s"
									>
										{t("global.modify")}
									</Button>
								)}
						</div>
						{contactVerification?.isPhoneNumberVerified &&
						values.phoneNumber === values.initialPhoneNumber ? (
							<InfoMessage color="success" withIcon>
								{t("personalInfos.contact.phoneVerified")}
							</InfoMessage>
						) : (
							hasPhoneNumber && (
								<InfoMessage color="warning" withIcon>
									<button
										type={"button"}
										onClick={async () => {
											await setFieldTouched(
												"phoneNumber",
												true,
												true
											);

											validateForm().then((err) => {
												if (!err?.phoneNumber) {
													setFieldValue(
														"phoneCheck",
														true
													);
												} else {
													if (!hasPhoneNumber) return;
													setFieldValue(
														"phoneNumber",
														values.initialPhoneNumber,
														true
													);
													setFieldValue(
														"initialPhoneNumber",
														"",
														true
													);
												}
											});
										}}
										className="text-start"
									>
										{t(
											"personalInfos.contact.phoneNotVerified"
										)}
									</button>
								</InfoMessage>
							)
						)}
					</div>
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-wrap xs:flex-nowrap w-full gap-2 [&>*]:w-full lg:[&>div]:min-w-full">
							<Field
								label={t("personalInfos.contact.mail")}
								name="email"
								type="text"
								readOnly={hasEmail}
								disabled={hasEmail}
							/>
							{values.email &&
								values.email === values.initialEmail && (
									<Button
										color={
											hasEmail ||
											(values.email && !errors.email)
												? "primary"
												: "grey"
										}
										type={
											!values.email &&
											values.email !== values.initialEmail
												? "button"
												: "submit"
										}
										onClick={() => {
											hasEmail
												? setFieldValue("email", "")
												: setFieldValue(
														"emailCheck",
														true
												  );
										}}
										className="!w-fit"
										size="s"
									>
										{t("global.modify")}
									</Button>
								)}
						</div>
						{contactVerification?.isEmailVerified &&
						values.email === values.initialEmail ? (
							<InfoMessage color="success" withIcon>
								{t("personalInfos.contact.emailVerified")}
							</InfoMessage>
						) : (
							hasEmail && (
								<InfoMessage color="warning" withIcon>
									<button
										type={"button"}
										onClick={async () => {
											await setFieldTouched(
												"email",
												true,
												true
											);

											validateForm().then((err) => {
												if (!err?.email) {
													setFieldValue(
														"emailCheck",
														true
													);
												} else {
													if (!hasEmail) return;
													setFieldValue(
														"email",
														values.initialEmail,
														true
													);
													setFieldValue(
														"initialEmail",
														"",
														true
													);
												}
											});
										}}
									>
										{t(
											"personalInfos.contact.emailNotVerified"
										)}
									</button>
								</InfoMessage>
							)
						)}
					</div>
				</div>
				{values.emailCheck && !sendingState.loading && (
					<ConfirmContactModal
						newValue={values.email}
						oldValue={values.initialEmail}
						setFieldValue={setFieldValue}
						onConfirmed={() => {
							if (!values.phoneCheck) refetchInfos();
							toast.success(
								t("personalInfos.contact.emailVerified")
							);
							setFieldValue("emailCheck", false);
						}}
						onClose={() => {
							setFieldValue("emailCheck", false);
						}}
						handleResend={() =>
							sendVerificationCode("email", values.email)
						}
						type="email"
					/>
				)}
				{values.phoneCheck &&
					!values.emailCheck &&
					!sendingState.loading && (
						<ConfirmContactModal
							newValue={values.phoneNumber}
							oldValue={values.initialPhoneNumber}
							setFieldValue={setFieldValue}
							onConfirmed={() => {
								if (!values.emailCheck) refetchInfos();

								toast.success(
									t("personalInfos.contact.phoneVerified")
								);
								setFieldValue("phoneCheck", false);
							}}
							onClose={() => {
								setFieldValue("phoneCheck", false);
							}}
							handleResend={() =>
								sendVerificationCode(
									"phone",
									values.phoneNumber
								)
							}
							type="phone"
						/>
					)}
			</div>
		</div>
	);
};

interface IConfirmContactModalProps {
	newValue: string;
	oldValue: string;
	onConfirmed?: () => void;
	onClose?: () => void;
	handleResend: () => Promise<any>;
	type?: "email" | "phone";
	setFieldValue: (field: string, value: any) => void;
}

const ConfirmContactModal: React.FC<IConfirmContactModalProps> = ({
	newValue,
	oldValue,
	onConfirmed,
	onClose,
	handleResend,
	type = "",
	setFieldValue,
}) => {
	const user = SessionService.getUser();

	const [sendingState, setSendingState] = React.useState({
		loading: false,
		error: "",
	});
	const [resendTimer, setResendTimer] = React.useState(false);

	const reSendCode = () => {
		const prom = handleResend().catch((err) => {
			setSendingState({
				loading: false,
				error: err.response.data.message,
			});
			throw err;
		});
		promiseToast(prom);
		setResendTimer(true);
		return prom;
	};

	const checkVerificationCode = (code: string) => {
		setSendingState({ loading: true, error: "" });
		const prom = RegisterService.putVerifySecurityToken({
			uniqueId: user.uniqueId,
			securityToken: code,
			[type]: {
				value: newValue,
			},
		}).then(
			(res) => {
				setSendingState({ loading: false, error: "" });
				onConfirmed && onConfirmed();
				return res;
			},
			(err) => {
				setSendingState({
					loading: false,
					error: err.response.data.message,
				});
				setFieldValue(
					type === "email" ? "email" : "phoneNumber",
					oldValue
				);
				throw err;
			}
		);
		return prom;
	};

	const { isLoading, error } = useQuery({
		queryKey: ["contactVerification", type, newValue],
		queryFn: () => {
			return handleResend();
		},
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
	});

	return (
		<ModalV2
			isDisplayed={true}
			onClose={onClose}
			title={
				type === "email"
					? t("personalInfos.contact.emailVerificationTitle")
					: t("personalInfos.contact.phoneVerificationTitle")
			}
			size="s"
		>
			{isLoading ? (
				<Spinner />
			) : !!error ? (
				<InfoMessage color="error" withIcon>
					Une erreur est survenue lors de l'envoi du code de
					vérification
				</InfoMessage>
			) : (
				<>
					<Text tag="p" size="paragraph01" className="mb-8">
						<Trans
							i18nKey={
								type === "email"
									? "registerCode.mailInfo"
									: "registerCode.phoneInfo"
							}
							values={{
								email: newValue,
								phone: newValue,
							}}
						/>
						<br />
						{t("signup.emailVerification.notReceived")}&nbsp;
						<button
							className={cn([
								"text-primary-500 font-bold",
								resendTimer && "opacity-70",
							])}
							disabled={resendTimer}
							onClick={resendTimer ? undefined : reSendCode}
						>
							{t("signup.emailVerification.resend")}
							{resendTimer && (
								<CountDown
									time={30}
									text={(time) => ` (${time})`}
									handleEnd={() => setResendTimer(false)}
								/>
							)}
						</button>
					</Text>
					<Formik
						initialValues={{
							activationCode: "",
						}}
						validateOnChange
						validateOnBlur={false}
						onSubmit={() => {
							// prop is mendatory but the submission is triggered by the validation
							// when the code reaches 4 characters
						}}
						validate={({ activationCode }) => {
							if (activationCode?.length === 4) {
								checkVerificationCode(activationCode);
							}
						}}
					>
						{() => {
							const isLoading = sendingState.loading;
							return (
								<Form>
									<div className="flex items-center gap-4">
										<Field
											name="activationCode"
											className={cn([
												"w-[200px] [&>div]:!rounded-2xl",
												"[&>*>input]:!pl-[1.2rem] [&>*>input]:!tracking-[0.4rem] [&>*>input]:!p-[10px] [&>*>input]:!text-center [&>*>input]:!text-2xl",
												isLoading &&
													"[&>*>input]:!text-neutral-500",
											])}
											type="text"
											label=""
											maxLength={4}
											readOnly={isLoading}
										/>
										{isLoading && <Spinner />}
									</div>
								</Form>
							);
						}}
					</Formik>
				</>
			)}
		</ModalV2>
	);
};

export default ContactInformations;
