import React, { useRef } from "react";
import { SelectAsync } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import Field from "../../components/molecules/Field/Field";
import cn from "../../utils/cn";
import { t } from "i18next";
import ProfileService from "../../services/ProfileService";
import {
	IPersonalInformationsCategory,
	IPersonalInformationsEditValues,
} from "./PersonalInformations";
import { noAccents } from "../../utils/noAccents";
import LocationService from "../../services/LocationService";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../components/atoms/Spinner/Spinner";
import { COUNTRY_NAME_FRANCE } from "../../constants/personalInformations";

const MIN_SEARCH_LENGTH = 2;

const AddressInformations: React.FC<IPersonalInformationsCategory> = ({
	disabled,
}) => {
	const fetchCountriesTM = useRef<NodeJS.Timeout>();

	const { values, setFieldValue } =
		useFormikContext<IPersonalInformationsEditValues>();

	const loadCountries = (searchTerm: string) => {
		clearTimeout(fetchCountriesTM.current);

		if (searchTerm?.length < MIN_SEARCH_LENGTH) return Promise.resolve([]);

		const prom = new Promise((resolve, reject) => {
			fetchCountriesTM.current = setTimeout(() => {
				return ProfileService.getCountries().then((resp) => {
					// filter items that name contains `inputValue`
					const filtered = resp.filter((i) =>
						noAccents(i.name.toLowerCase()).includes(
							noAccents(searchTerm.toLowerCase())
						)
					);

					resolve(filtered);
					return filtered;
				}, reject);
			}, 500);
		});

		return prom;
	};

	const fetchUserLocation = () =>
		LocationService.getUserLocations().then((resp) => {
			const mainLocation = {
				id: 0,
				name: "",
				...resp?.mainLocation,
				postalCode: resp?.mainLocation?.postalCode ?? "00000",
			};
			setFieldValue("mainLocation", mainLocation);
			return mainLocation;
		});

	const { isLoading } = useQuery(
		["profile", "personalInformations", "locations"],
		fetchUserLocation,
		{
			refetchOnWindowFocus: false,
		}
	);

	if (isLoading)
		return (
			<>
				<b>{t("personalInfos.address.title")}</b>
				<Spinner className="mt-4" />
			</>
		);

	return (
		<div className={cn([disabled && "cursor-not-allowed", "w-full"])}>
			<div className="relative flex flex-col items-start justify-start gap-4">
				<div
					className={cn([
						"flex flex-col gap-2 w-full",
						disabled && "pointer-events-none opacity-70",
					])}
				>
					<b>{t("personalInfos.address.title")}</b>
					<div className="flex flex-col gap-2 [&>div>*]:w-full lg:[&>div>*]:w-[calc(50%-0.4rem)] xl:[&>div>*]:w-[calc(100%/3-0.5rem)]">
						<div className="flex flex-col flex-wrap gap-3 md:flex-row">
							<Field
								label={t("personalInfos.address.label")}
								name="addressStreet"
								type="text"
								maxLength={30}
							/>
							<Field
								label={t("personalInfos.address.complement")}
								name="addressBuilding"
								type="text"
								maxLength={30}
							/>
						</div>
						<div className="flex flex-col flex-wrap gap-3 md:flex-row">
							{values?.addressCountry?.name ===
							COUNTRY_NAME_FRANCE ? (
								<MainLocationSelect
									values={values}
									setFieldValue={setFieldValue}
									disabled={disabled}
								/>
							) : (
								<>
									<Field
										label={t("personalInfos.address.city")}
										name="addressCity"
										type="text"
									/>
									<Field
										label={t(
											"personalInfos.address.postalCode"
										)}
										name="addressPostalCode"
										type="text"
									/>
								</>
							)}
							<SelectAsync
								defaultOptions
								loadOptions={loadCountries}
								label={t("personalInfos.address.country")}
								value={values.addressCountry}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
								searchIcon={true}
								isSearchable={true}
								onChange={(value) => {
									setFieldValue("addressCountry", value);
								}}
								className="[&>p:empty]:hidden"
								name="addressCountry"
								noOptionsMessage={({ inputValue }) => {
									if (inputValue?.length < MIN_SEARCH_LENGTH)
										return t("search.searchMinLength", {
											min: MIN_SEARCH_LENGTH,
										});
									return t("search.noResult", {
										search: inputValue,
									});
								}}
								loadingMessage={() => t("search.loading")}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const MainLocationSelect: React.FC<any> = ({ values, setFieldValue }) => {
	const fetchTM = useRef<NodeJS.Timeout>();

	const formatLabel = (option: any) => {
		return `${option.name} ${
			option.postalCode && option.postalCode !== "00000"
				? `(${option.postalCode})`
				: ""
		}`;
	};

	const handleSearch = (searchTerm: string) => {
		clearTimeout(fetchTM.current);
		if (searchTerm?.length < MIN_SEARCH_LENGTH) return Promise.resolve([]);

		const prom = new Promise((resolve, reject) => {
			fetchTM.current = setTimeout(() => {
				return LocationService.getSearchLocations(searchTerm).then(
					resolve,
					reject
				);
			}, 500);
		});

		return prom;
	};

	return (
		<SelectAsync
			defaultOptions
			loadOptions={handleSearch}
			label={t("personalInfos.address.cityPostalCode")}
			value={values.mainLocation}
			getOptionLabel={formatLabel}
			getOptionValue={(option) => option.id}
			searchIcon={true}
			isSearchable={true}
			onChange={(value) => setFieldValue("mainLocation", value)}
			name="mainLocation"
			noOptionsMessage={({ inputValue }) => {
				if (inputValue?.length < MIN_SEARCH_LENGTH)
					return t("search.searchMinLength", {
						min: MIN_SEARCH_LENGTH,
					});
				return t("search.noResult", {
					search: inputValue,
				});
			}}
			loadingMessage={() => t("search.loading")}
		/>
	);
};

export default AddressInformations;
