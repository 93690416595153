import React from "react";
import Login from "../views/Login/Login";
import Register from "../views/Register/Register";
import ResetPassword from "../views/ResetPassword/ResetPassword";
import RetrievePassword from "../views/RetrievePassword/RetrievePassword";
import RetrieveLogin from "../views/RetrieveLogin/RetrieveLogin";
import Home from "../views/Home/Home";
import Help from "../views/Help/Help";
import HomeSideBox from "../views/Home/HomeSideBox";
import ForgotLogin from "../views/ForgotLogin/ForgotLogin";
import ContactAgencies, {
	AuthContactAgencies,
} from "../views/ContactAgencies/ContactAgencies";
import Availabilities from "../views/Avalabilities/Availabilities";
import BankInfos from "../views/BankInfos/BankInfos";
import Payslips from "../views/Payslips/Payslips";
import Absences from "../views/Absences/Absences";
import Contracts from "../views/Contracts/Contracts";
import Cookies from "../views/Cookies/Cookies";

import i18n from "../i18n/i18n";
import { InfoMessage } from "@zolteam/react-ras-library";

/* Icons */
import HomePicto from "./pictos/home.svg";
import DocPicto from "./pictos/doc.svg";
import CreditCardPicto from "./pictos/creditCard.svg";
import CalendarPicto from "./pictos/calendar.svg";
import OfferPicto from "./pictos/search.svg";
import ProfilePro from "./pictos/profilePro.svg";

import { DefaultTFuncReturn, t } from "i18next";
import Page404 from "../views/404/404";
import Messagerie from "../views/Messagerie/Messagerie";
import SVG from "../components/atoms/SVG/SVG";
import Initialize from "../views/Initialize/Initialize";
import InitializeDone from "../views/Initialize/InitializeDone";
import Offers from "../views/Offers/Offers";
import FavoritesOffers from "../views/Offers/Favorites";
import Applications from "../views/Offers/Applications";
import CV from "../views/ProfilPro/CV";
import AuthLayout from "../components/templates/AuthLayout/AuthLayout";
import Settings from "../views/Settings/Settings";
import SignUp from "../views/SignUp/SignUp";
import SignUpConsent from "../views/SignUp/SignUpConsent";
import SignUpExistingUser from "../views/SignUp/SignUpExistingUser";
import SignUpEmailVerification from "../views/SignUp/SignUpEmailVerification";

import SignUpPassword from "../views/SignUp/SignUpPassword";
import Onboarding from "../views/Onboarding/Onboarding";
import Workplaces from "../views/ProfilPro/Workplaces";
import MasterCategories from "../views/ProfilPro/MasterCategories";
import ProDocs from "../views/ProfilPro/ProDocs";
import ContractsTypes from "../views/ProfilPro/ContractsTypes";
import PersonalInformations from "../views/PersonalInformations/PersonalInformations";
import IdentityDocs from "../views/IdentityDocs/IdentityDocs";
import AdvancesPayment from "../views/AdvancesPayment/AdvancesPayment";
import { WorkCertificates } from "../views/WorkCertificates/WorkCertificates";

export interface IRoute {
	name?: string | DefaultTFuncReturn;
	path?: string | string[];
	element?: React.ReactNode;
	sideElement?: React.ReactNode;
	icon?: string | React.ReactNode;
	exact?: boolean;
	active?: boolean;
	childrens?: IRoute[];
	scrollable?: boolean; // si true, le scroll de l'AdminLayout est désactivé et doit être géré dans le composant
	devOnly?: boolean; // si true, la route n'est accessible qu'en dev (root.dev() doit être appelé dans la console)
	devFeatureName?: string; // si défini, la route n'est accessible qu'en dev si la feature est activée
	disabled?: boolean; // si true, la route est désactivée
}

const defaultRoutes: IRoute[] = [
	{
		name: t("errors.404.pageTitle"),
		element: <Page404 />,
		path: "*", // This path is used to match all routes
	},

	{
		name: "Cookies",
		element: <Cookies />,
		path: "/cookies",
	},
];

const loginRoutes: IRoute[] = [
	{
		name: t("auth.title"),
		path: ["*", "/connexion/:uniqId"],
		element: <Login />,
		exact: true,
	},
	{
		name: t("resetPassword.successTitle"),
		path: "/login/reseted-password",
		element: (
			<Login
				title={t("resetPassword.successTitle").toString()}
				noInitMessage
				noSignUpMessage
			>
				<div className="mt-8">
					<InfoMessage withIcon color="success">
						{t("resetPassword.success")}
					</InfoMessage>
				</div>
			</Login>
		),
		exact: true,
	},
	{
		name: t("retrieveLogin.title"),
		path: "/login-retrieve",
		element: <RetrieveLogin />,
		exact: true,
	},
	{
		name: t("retrievePassword.title"),
		path: "/password-retrieve",
		element: <RetrievePassword />,
		exact: true,
	},
	{
		name: t("resetPassword.title"),
		path: [
			"/password-reset",
			"/password-reset/:token",
			"/connexion/reinitialisation/:token",
		],
		element: <ResetPassword />,
		exact: true,
	},
	{
		name: t("forgotId.title"),
		path: "/forgot-login",
		element: <ForgotLogin />,
		exact: true,
	},
];

const signupRoutes: IRoute[] = [
	{
		name: "Signup",
		path: "/signup",
		element: <SignUp />,
	},
	{
		name: "Signup consent",
		path: "/signup-consent",
		element: <SignUpConsent />,
	},
	{
		name: "Existing account retrieval",
		path: "/retrieve-existing-account",
		element: <SignUpExistingUser />,
	},
	{
		name: "Email verification",
		path: ["/signup-email-verification", "/confirmation-email/:token"],
		element: <SignUpEmailVerification />,
	},
	{
		name: "Password set",
		path: "/signup-password",
		element: <SignUpPassword />,
	},
];

const initAccountRoutes: IRoute[] = [
	{
		name: t("initialize.title"),
		path: "/initialize",
		element: <Initialize />,
		exact: true,
	},
	{
		name: t("initialize.successTitle"),
		path: "/initialize-done",
		element: <InitializeDone />,
		exact: true,
	},
];

const registerRoutes: IRoute[] = [
	{
		name: "Register",
		path: [
			"/register/:token/:origin",
			"/initialisation-compte/:token/:origin",
		],
		element: <Register />,
		exact: true,
	},
	{
		name: t("global.contactAgency"),
		path: "/contact-agencies",
		element: <AuthContactAgencies />,
		exact: true,
	},
	{
		name: t("global.needHelp"),
		element: (
			<AuthLayout>
				<div className="pb-12">
					<Help />
				</div>
			</AuthLayout>
		),
		path: "/help",
	},
];

const onboardingRoutes: IRoute[] = [
	{
		name: "Onboarding",
		path: "/onboarding",
		element: <Onboarding />,
		exact: true,
	},
];

export const menuRoutes: IRoute[] = [
	{
		name: i18n.t("global.home"),
		icon: <SVG src={HomePicto} />,
		element: <Home />,
		sideElement: <HomeSideBox />,
		path: ["/", "/accueil"],
		active: true,
	},
	{
		name: t("personalInfos.title"),
		path: ["/personal-informations", "/identity-docs"],
		element: <PersonalInformations />,
		icon: <SVG src={ProfilePro} />,
		childrens: [
			{
				name: t("personalInfos.title"),
				path: ["/personal-informations"],
				element: <PersonalInformations />,
			},
			{
				name: t("personalInfos.myDocs"),
				path: ["/identity-docs"],
				element: <IdentityDocs />,
			},
		],
	},
	{
		name: i18n.t("global.offers"),
		icon: <SVG src={OfferPicto} />,
		childrens: [
			{
				name: i18n.t("offers.searchOffers.title"),
				path: ["/offers", "/offers/:id"],
				element: <Offers />,
			},
			{
				name: i18n.t("offers.applications.title"),
				path: ["/offers/applications", "/offers/applications/:id"],
				element: <Applications />,
			},
			{
				name: i18n.t("offers.favoriteOffers.title"),
				path: ["/offers/favorites", "/offers/favorites/:id"],
				element: <FavoritesOffers />,
			},
		],
	},
	{
		name: i18n.t("global.profilePro"),
		icon: <SVG src={ProfilePro} />,
		childrens: [
			{
				name: i18n.t("profilePro.cv.title"),
				path: "/cv",
				element: <CV />,
			},
			{
				name: "Documents pro",
				path: "/pro-docs",
				element: <ProDocs />,
			},
			{
				name: i18n.t("profilePro.masterCategories.title"),
				path: "/masterCategories",
				element: <MasterCategories />,
			},
			{
				name: i18n.t("profilePro.workplaces.title"),
				path: "/workplaces",
				element: <Workplaces />,
			},
			{
				name: i18n.t("profilePro.contractsTypes.title"),
				path: "/contractsTypes",
				element: <ContractsTypes />,
			},
		],
	},
	{
		name: i18n.t("global.contracts"),
		path: ["/contracts", "/contrats"],
		element: <Contracts />,
		icon: <SVG src={DocPicto} />,
		exact: true,
	},
	{
		name: i18n.t("global.payment"),
		icon: <SVG src={CreditCardPicto} />,
		childrens: [
			{
				name: i18n.t("global.payslips"),
				path: ["/payslips", "/salaires"],
				element: <Payslips />,
			},
			{
				name: i18n.t("global.bankInfos"),
				path: "/bank-infos",
				element: <BankInfos />,
			},
			{
				name: i18n.t("global.advancePayment"),
				path: "/advance-payment",
				element: <AdvancesPayment />,
			},
			{
				name: i18n.t("workCertificates.title"),
				path: ["/work-certificates", "/work-certificates/:id"],
				element: <WorkCertificates />,
			},
		],
	},
	{
		name: i18n.t("global.availabilitiesTab"),
		icon: <SVG src={CalendarPicto} />,
		childrens: [
			{
				name: i18n.t("global.availabilities"),
				path: "/availabilities",
				element: <Availabilities />,
			},
			{
				name: i18n.t("global.absences"),
				path: "/absences",
				element: <Absences />,
			},
		],
	},
];

const othersPrivateRoutes: IRoute[] = [
	{
		name: t("global.contactAgency"),
		path: "/contact-agencies",
		element: <ContactAgencies />,
		exact: true,
	},
	{
		name: t("global.contracts"),
		path: ["/contracts/:id", "/contrats/:id"],
		element: <Contracts />,
		exact: true,
	},
	{
		name: t("global.payslips"),
		path: "/payslips/:id",
		element: <Payslips />,
		exact: true,
	},
	{
		name: "Settings",
		path: "/settings",
		element: <Settings />,
		exact: true,
	},
];

const headerRoutes: IRoute[] = [
	{
		name: t("messaging.title"),
		path: ["/messagerie", "/messagerie/:messageId"],
		element: <Messagerie />,
		exact: true,
	},
	{
		name: t("global.needHelp"),
		element: <Help />,
		path: "/help",
	},
];

// Concat routes

export const publicRoutes: IRoute[] = [
	...signupRoutes,
	...loginRoutes,
	...initAccountRoutes,
	...registerRoutes,
	...onboardingRoutes,
	...defaultRoutes,
];

export const privateRoutes: IRoute[] = [
	...defaultRoutes,
	...menuRoutes,
	...othersPrivateRoutes,
	...headerRoutes,
];
