import api from "./api";
import { TAllowedAgenciesResponse, TWorkCertificateRequest } from "../types";

class WorkCertificatesClass {
	getWorkCertificates = (signal: AbortSignal) =>
		api
			.get("/api-360/work-certificates", { signal })
			.then((response) => response.data);

	getAllowedAgencies = (): Promise<TAllowedAgenciesResponse> =>
		api
			.get("/api-360/worker/agencies-allowed-for-work-certificate")
			.then((response) => response.data);

	generateWorkCertificate = (data: TWorkCertificateRequest) =>
		api
			.post("/api-360/work-certificates", data)
			.then((response) => response.data);
}

const WorkCertificatesService = new WorkCertificatesClass();
export default WorkCertificatesService;
