import React, { useCallback, useState } from "react";
import { t } from "i18next";
import cn from "../../../utils/cn";
import "./ImageViewer.scss";
import moment from "moment";
import { QueryFunction, QueryKey } from "@tanstack/react-query";
import { urlToBase64 } from "../../../utils/blob";
import ViewerNavigation from "../ViewerNavigation/ViewerNavigation";
import { getImageSize } from "../../../utils/image";
import { PROXY_URL } from "../../../constants/constants";

interface IImageViewerProps {
	className?: string;
	file?: string;
	isOpen?: boolean;
	onBack?: () => void;
	download?: boolean;
	downloadTitle?: string;
	query?:
		| {
				key: QueryKey;
				fn: QueryFunction;
				options?: any;
		  }
		| undefined;
}

const ImageViewer: React.FC<IImageViewerProps> = ({
	file = null,
	onBack = () => {},
	download = true,
	className,
	downloadTitle = t("docs.fileName") + moment().format("DD_MM_YYYY_HH_mm_ss"),
}) => {
	// needed for it to work
	const [Error, setError] = useState(null);
	const [Zoom, setZoom] = useState(1);
	const [ScrollEvent, setScrollEvent] = useState(null);
	const contRef = React.useRef(null);
	const [Image, setImage] = useState({
		src: file ?? null,
		width: 0,
		height: 0,
		ratio: 0,
	});

	// handle zoom with scroll + alt key
	const handleScroll = useCallback((e: any) => {
		setScrollEvent(e);
	}, []);

	const downloadFile = (data) => {
		if (!data) return null;
		const link = document.createElement("a");
		link.href = data;
		link.target = "_blank";
		link.download = `${downloadTitle ?? t("docs.fileName")}`;
		link.click();
	};

	const handleDownload = () => {
		const fileToUse = Image.src;

		let isUrl = typeof fileToUse === "string" && fileToUse.includes("http");
		if (!isUrl) return;

		let prom = urlToBase64((PROXY_URL ?? "") + fileToUse);

		prom.then(
			(base64) => {
				downloadFile(base64);
			},
			() => {
				downloadFile(Image.src);
			}
		);

		return prom;
	};

	const handleBack = () => {
		onBack();
	};

	React.useEffect(() => {
		if (!file) return;
		const container = contRef.current;
		if (!container) return;

		getImageSize(file).then(({ height, width }: any) => {
			setZoom(1);
			setImage({
				src: file,
				width: width,
				height: height,
				ratio: width / height,
			});
		}, setError);
	}, [file]);

	React.useEffect(() => {
		// replace horizontal scroll to the middle
		const container: any = contRef.current;
		if (!container) return;
		container.scrollLeft =
			(container.scrollWidth - container.clientWidth) / 2;
	}, [Zoom]);

	React.useEffect(() => {
		const container: any = contRef.current;
		if (!container) return;
		container.addEventListener("wheel", handleScroll);
		return () => {
			container.removeEventListener("wheel", handleScroll);
		};
	}, [handleScroll]);

	return (
		<div
			className={cn(["ImageViewer", className])}
			style={{
				"--viewer-scale-factor": Zoom,
			}}
		>
			<ViewerNavigation
				isLoading={false}
				Zoom={Zoom}
				onZoom={setZoom}
				download={download}
				onDownload={handleDownload}
				onBack={handleBack}
				disabled={Error !== null}
				className="ImageViewer__nav"
				scrollEvent={ScrollEvent}
			/>

			<div
				ref={contRef}
				className="inline-grid w-full h-full overflow-auto ImageViewer__container bg-neutral-100"
			>
				<div
					className="p-4 mx-auto my-4 "
					style={{
						width: `${
							Image.width < Image.height
								? Image.ratio * 95 * Zoom
								: 95 * Zoom
						}%`,
						height: `${
							Image.width > Image.height
								? (95 / Image.ratio) * Zoom
								: 95 * Zoom
						}%`,
						backgroundImage: `url(${Image.src})`,
						backgroundPosition: "center",
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						margin: "auto",
					}}
				></div>
			</div>
		</div>
	);
};

export default ImageViewer;
