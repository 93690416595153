import { useQueryClient } from "@tanstack/react-query";
import { Title, BackLink } from "@zolteam/react-ras-library";
import { t } from "i18next";
import moment from "moment";
import React from "react";
import { Trans } from "react-i18next";
import DocumentsService from "../../../services/DocumentsService";
import AddDocModal from "../../organisms/AddDocModal/AddDocModal";
import DocsLayout from "../DocsLayout/DocsLayout";
import { promiseToast } from "../../../toastify/toastify";
import cn from "../../../utils/cn";
import { isObjectEmpty } from "../../../utils/objects";

export interface IDocType {
	id: number;
	typeName?: string;
	name?: string;
	addTitle?: string;
	editTitle?: string;
	addModal?: React.ReactNode;
	noDataMessage?: string | React.ReactNode;
	acceptedFilesType?: string;
}

interface IProDocsDrawer {
	onClose?: () => void;
	type: IDocType;
	tileComponent?: any;
	titleClassName?: string;
	noDataComponent?: "empty-tile" | React.ReactNode;
	isPersonalDoc?: boolean;

	isDocTypeLocked?: (docType: any) => boolean;
	lockedDocMessage?: string | ((docType: any) => string | React.ReactNode);
}

const ProDocsDrawer: React.FC<IProDocsDrawer> = ({
	tileComponent,
	titleClassName,
	noDataComponent,
	onClose,
	type,
	isPersonalDoc,
	isDocTypeLocked,
	lockedDocMessage,
}) => {
	const [Docs, setDocs] = React.useState<any[]>([]);
	const queryClient = useQueryClient();

	const getDocObject = (attachmentId: number) => {
		const found = Docs.find((d) => {
			const attachments = d.files;
			if (d.id === attachmentId) return true;
			const foundAttachment = attachments.find((attachment) => {
				return attachment.id === attachmentId;
			});
			return foundAttachment;
		});
		return found;
	};

	const fetch = () => {
		return DocumentsService.getAllProofsTypesForId(type.id).then(
			(typeRes) => {
				return DocumentsService.getAllUserDocuments(type.id).then(
					(res) => {
						setDocs(res.unifiedTempWorkerDocument);
						return res.unifiedTempWorkerDocument;
					}
				);
			}
		);
	};

	const handleDocDelete = (document: any, fromAdd: boolean) => {
		const doc = getDocObject(document.id);

		let proms = [];

		if (!doc) return Promise.reject("No doc found");
		if (doc.files?.length)
			proms = doc.files.map((file) => {
				return DocumentsService.deleteDocumentAttachment(file.uuid);
			});

		const prom = Promise.all(proms).then(() => {
			return DocumentsService.deleteDocument(doc.id);
		});

		promiseToast(prom, {
			success: t(
				`profilePro.proDocs.${
					fromAdd ? "oldDoc.delete" : "delete"
				}.success`
			),
			error: t(
				`profilePro.proDocs.${
					fromAdd ? "oldDoc.delete" : "delete"
				}.error`
			),
			pending: t(
				`profilePro.proDocs.${
					fromAdd ? "oldDoc.delete" : "delete"
				}.pending`
			),
		});
		return prom;
	};

	const handleAttachmentDelete = (attachment, doc) => {
		let prom: Promise<any>;
		let fullRemove = false;
		if (doc.files?.length === 1) {
			prom = handleDocDelete(doc, false);
			fullRemove = true;
		} else {
			prom = DocumentsService.deleteDocumentAttachment(attachment.uuid);
		}
		prom.then((resp) => {
			queryClient.resetQueries(["ProDocs", type.typeName]);
			return resp;
		});

		if (!fullRemove)
			promiseToast(prom, {
				pending: t("profilePro.proDocs.attachments.delete.pending"),
				success: t("profilePro.proDocs.attachments.delete.success"),
				error: t("profilePro.proDocs.attachments.delete.error"),
			});
		return prom;
	};

	const handleSubmit = (values: any) => {
		let deleteExistingDocProm: Promise<any> = Promise.resolve();
		if (Docs.length) {
			const found = Docs.find((d) => {
				return d.commonDocumentId === values.type.value;
			});
			if (found) {
				deleteExistingDocProm = handleDocDelete(found, true);
			}
		}
		const mainProm = new Promise((resolve, reject) => {
			return deleteExistingDocProm.then(() => {
				return DocumentsService.uploadDocument({
					reference: values.reference ?? "",
					effectiveDate: values.effectiveDate?.length
						? moment(
								values.effectiveDate,
								t("dates.format").toString()
						  ).format(t("dates.apiFormat").toString())
						: "",
					expirationDate: values.expirationDate?.length
						? moment(
								values.expirationDate,
								t("dates.format").toString()
						  ).format(t("dates.apiFormat").toString())
						: "",
					permanent: values.type.permanentRule ? true : null,
					attachments: values.files.map((file) => {
						const fileObj = file.raw;
						const ext = fileObj.name.split(".").pop();
						const name = values.type.name + "." + ext;
						const newFile = new File([fileObj], name, {
							type: fileObj.type,
						});
						return newFile;
					}),
					commonDocumentId: values.type.value,
				}).then((res) => {
					queryClient.resetQueries(["ProDocs", type.typeName]);
					resolve(res);
					return res;
				}, reject);
			}, reject);
		});

		deleteExistingDocProm.then(() => {
			promiseToast(mainProm, {
				pending: "Ajout du document",
				success: "Document ajouté",
				error: "Erreur lors de l'ajout du document",
			});
		});

		return mainProm;
	};

	return (
		<div className="flex-col h-full fldex overflow-hiddden">
			<div className="flex flex-col">
				<Title tag="h2" className={cn(["!leading-5", titleClassName])}>
					{type?.name}
				</Title>
				{onClose && (
					<BackLink
						className="[&>*]:!text-primary-500"
						onClick={onClose}
					>
						{t("profilePro.proDocs.title")}
					</BackLink>
				)}
			</div>
			<DocsLayout
				isPersonalDoc={isPersonalDoc}
				title={false}
				addTitle={type?.addTitle}
				editTitle={type?.editTitle ?? type?.addTitle}
				addModal={(props) => {
					return (
						<AddDocModal
							{...props}
							type={!isObjectEmpty(type) ? type : null}
							isPersonalDoc={isPersonalDoc}
							handleSubmit={handleSubmit}
							isDocTypeLocked={isDocTypeLocked}
							lockedDocMessage={lockedDocMessage}
						/>
					);
				}}
				query={{
					key: ["ProDocs", type.typeName],
					fn: fetch,
					opts: {
						refetchOnWindowFocus: false,
						refetchOnMount: false,
					},
				}}
				onUpload={(res) => {
					return Promise.resolve();
				}}
				tileTitle={(doc) => {
					return doc.commonDocumentName ?? doc.name;
				}}
				onRemove={(uuid) => handleDocDelete(uuid, false)}
				onRemoveAttachment={handleAttachmentDelete}
				noDataMessage={
					<Trans i18nKey={type.noDataMessage?.toString()} />
				}
				noDataComponent={noDataComponent}
				acceptedFilesType={type.acceptedFilesType}
				tileComponent={tileComponent}
				type={type}
			/>
		</div>
	);
};

export default ProDocsDrawer;
