import React from "react";
import { SelectAsync } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import Field from "../../components/molecules/Field/Field";
import cn from "../../utils/cn";
import { t } from "i18next";
import ProfileService from "../../services/ProfileService";
import {
	IPersonalInformationsCategory,
	IPersonalInformationsEditValues,
} from "./PersonalInformations";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../components/atoms/Spinner/Spinner";

const FamilySituationInformations: React.FC<IPersonalInformationsCategory> = ({
	infos,
	disabled,
	refetchInfos,
}) => {
	const { values, setValues, setFieldValue } =
		useFormikContext<IPersonalInformationsEditValues>();

	const getSelectedOption = (values: any) => {
		if (!values || !infos.familySituation) return null;
		return values.find((v: any) => v.name === infos.familySituation);
	};

	const fetchOptions = () =>
		ProfileService.getFamilySituationList().then((resp) => {
			setFieldValue("familySituationOption", getSelectedOption(resp));
			return resp;
		});

	const { isLoading } = useQuery(["familySituationList"], fetchOptions);

	if (isLoading)
		return (
			<>
				<b>{t("personalInfos.situation.title")}</b>
				<Spinner />;
			</>
		);

	return (
		<div className={cn([disabled && "cursor-not-allowed", "w-full"])}>
			<div className="relative flex flex-col items-start justify-start gap-4">
				<div
					className={cn([
						"flex flex-col gap-2 w-full",
						disabled && "pointer-events-none opacity-70",
					])}
				>
					<b>{t("personalInfos.situation.title")}</b>
					<div className="flex flex-col gap-2 md:flex-row [&>div]:w-full lg:[&>div]:w-[calc(25%-0.25rem)] xl:[&>div]:w-[16%]">
						<SelectAsync
							defaultOptions
							loadOptions={fetchOptions}
							inputValue={
								!values.familySituationOption
									? values.familySituation
									: ""
							}
							label={t("personalInfos.situation.label")}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							value={values.familySituationOption}
							name="familySituationOption"
							onChange={(value) => {
								setValues({
									...values,
									familySituationOption: value,
									familySituation: value.name,
								});
							}}
						/>
						<Field
							label={t("personalInfos.situation.childs")}
							name="childrenQty"
							type="number"
							min="0"
							max="10"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FamilySituationInformations;
