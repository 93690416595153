import React, { useState } from "react";
import ListLayout from "../../components/templates/ListLayout/ListLayout";

import WorkCertificatesService from "../../services/WorkCertificatesService";
import workCertificates from "../../assets/illustrations/workCertificates.svg";
import ListLayoutItem from "../../components/molecules/ListLayoutItem/ListLayoutItem";
import { Button, ModalV2, InfoMessage } from "@zolteam/react-ras-library";
import { t } from "i18next";
import { GenerateWorkCertificateForm } from "../../forms/GenerateWorkCertificateForm/GenerateWorkCertificateForm";
import { promiseToast } from "../../toastify/toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import PDFViewer from "../../components/molecules/PDFViewer/PDFViewer";
import { TWorkCertificate, TWSTATUS_WAITING } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import SessionService from "../../services/SessionService";

export const WorkCertificates: React.FC = () => {
	const { id: paramId } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const twStatus = SessionService.getUser().twStatus;
	const [hasWorkCertificates, setHasWorkCertificates] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [ErrorMessage, setErrorMessage] = useState<string | null>(null);

	const fetchWorkCertificates = ({ signal }: { signal: AbortSignal }) =>
		WorkCertificatesService.getWorkCertificates(signal).then((res) => {
			setHasWorkCertificates(!!res.workCertificateFiles.length);

			return {
				items: res.workCertificateFiles?.sort((a, b) => {
					return (
						new Date(b.workCertificateDate).getTime() -
						new Date(a.workCertificateDate).getTime()
					);
				}),
			};
		});

	const handleCertificateGeneration = (values) => {
		setIsSubmitting(true);
		setErrorMessage(null);

		const prom = WorkCertificatesService.generateWorkCertificate({
			agencyId: values.agency.id,
			userId: values.agency.userId,
			workCertificateDate: moment(
				values.agency.lastAllowedDate,
				"YYYY-MM"
			).format("YYYY-MM-DD"),
		}).then(
			(resp) => {
				setIsModalVisible(false);
				navigate(`/work-certificates/${resp.uuid}`);
				queryClient.resetQueries(["workCertificates"]);
			},
			(e) => {
				setErrorMessage(t("workCertificates.generationError"));
				return Promise.reject(e);
			}
		);

		prom.finally(() => {
			setIsSubmitting(false);
		});

		promiseToast(
			prom,
			{
				pending: t("workCertificates.pendingGeneration"),
				success: t("workCertificates.generationSuccess"),
			},
			{
				toastId: "workCertificatesGeneration",
			}
		);

		return prom;
	};

	const formatDownloadTitle = (doc: TWorkCertificate) => {
		return `${t("workCertificates.fileName")}_${doc.agencyName?.replace(
			/\s/g,
			"_"
		)}_${moment(doc.workCertificateDate).format("YYYY_MM")}`;
	};

	const { isLoading, data, error } = useQuery({
		queryFn: () => {
			if (twStatus === TWSTATUS_WAITING)
				return Promise.resolve({ allowedAgencies: [] });
			return WorkCertificatesService.getAllowedAgencies().catch(() => {
				return { allowedAgencies: [] };
			});
		},
		queryKey: ["WorkCertificates", "agencies"],
		refetchOnWindowFocus: false,
		retry: false,
	});

	const genInfoMessage = () => {
		if (!data?.allowedAgencies?.length)
			return twStatus === TWSTATUS_WAITING
				? t("workCertificates.cantGenerate")
				: t("workCertificates.noAvailableCertificates");

		if (!hasWorkCertificates)
			return t("workCertificates.noWorkCertificates");

		return t("workCertificates.generateMessage");
	};

	const infoMessage = genInfoMessage();

	return (
		<div className="flex flex-col h-full">
			<ListLayout
				title={t("workCertificates.tileTitle")}
				query={{
					key: ["workCertificates", "list"],
					fn: fetchWorkCertificates,
					opts: {
						retry: false,
					},
				}}
				backgroundImage={workCertificates}
				noDataMessage={infoMessage}
				filters={false}
				pagination={false}
				activeItemId={paramId ?? null}
				itemIdKey="uuid"
				baseUrl="/work-certificates"
				viewer={{
					render: ({ item, closeViewer }) => {
						return (
							<PDFViewer
								onBack={closeViewer}
								download={true}
								downloadTitle={
									item ? formatDownloadTitle(item) : undefined
								}
								file={item?.link ?? false}
							/>
						);
					},
				}}
			>
				{(item) => (
					<ListLayoutItem
						id={item.id}
						title={t("workCertificates.itemLabel", {
							date: moment(item.workCertificateDate).format(
								"MMMM YYYY"
							),
						})}
						infos={item.agencyName}
						path={item.link}
					/>
				)}
			</ListLayout>
			<div className="sticky bottom-0 flex flex-row flex-wrap items-center justify-end gap-4 pt-2 pb-4 bg-white">
				{!isLoading && !error && (
					<>
						{hasWorkCertificates && (
							<div className="flex w-full [&>div]:mt-0 justify-end max-w-[500px] ml-auto">
								<InfoMessage color="primary" withIcon>
									{infoMessage}
								</InfoMessage>
							</div>
						)}

						<Button
							color={
								!data?.allowedAgencies?.length
									? "grey"
									: "primary"
							}
							size="m"
							type="button"
							className="w-full md:w-auto !p-1 !px-3"
							onClick={() => {
								data?.allowedAgencies?.length === 1
									? handleCertificateGeneration({
											agency: data?.allowedAgencies[0],
									  })
									: setIsModalVisible(true);
							}}
							isLoading={isLoading || isSubmitting}
							disabled={!data?.allowedAgencies?.length}
						>
							{t("workCertificates.generate")}
						</Button>
					</>
				)}
			</div>
			<ModalV2
				title={t("workCertificates.generate")}
				isDisplayed={isModalVisible}
				onClose={() => !isSubmitting && setIsModalVisible(false)}
				size={"s"}
			>
				<GenerateWorkCertificateForm
					onSubmit={handleCertificateGeneration}
					errorMessage={ErrorMessage}
				/>
			</ModalV2>
		</div>
	);
};
