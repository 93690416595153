import { t } from "i18next";
import { PROXY_URL } from "../constants/constants";

// function to retrieve a base64 file from a blob
export const blobToBase64 = (blob) => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise((resolve) => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

export const blobToArrayBuffer = (blob) => {
	const reader = new FileReader();
	reader.readAsArrayBuffer(blob);
	return new Promise((resolve) => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

// function to retrieve a base64 file from a url
export const urlToBase64 = (url) =>
	fetch(url)
		.then((response) => response.blob())
		.then((blob) => blobToBase64(blob));

export const urlToArrayBuffer = (url) =>
	fetch(url)
		.then((response) => response.arrayBuffer())
		.then((arrayBuffer) => arrayBuffer);

export const handleFileDownload = (file, title, extension, callback?) => {
	let isBlob = file instanceof Blob;
	let isUrl = typeof file === "string" && file.includes("http");

	let prom = Promise.resolve(file);
	if (isUrl) {
		prom = urlToBase64((PROXY_URL ?? "") + file);
	} else if (isBlob) {
		prom = blobToBase64(file);
	}

	prom.then((base64) => {
		const link = document.createElement("a");
		link.href = base64;
		link.target = "_blank";
		link.download = `${title ?? t("docs.fileName")}.${extension}`;
		link.click();
		callback && callback();
	});
};
