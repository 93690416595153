import { t } from "i18next";
import * as Yup from "yup";
import { EMAIL_REGEX, PHONE_REGEX } from "../../utils/regex";
import { isValidSSSN } from "../../utils/personalInformations";

const IdentityInformationsValidationSchema = {
	bornCity: Yup.string().nullable(),
	nativeCountry: Yup.object().shape({ name: Yup.string(), id: Yup.string() }),
	nationality: Yup.object()
		.nullable()
		.shape({ name: Yup.string(), id: Yup.string() }),
};

const SocialSecurityValidationSchema = {
	ssn: Yup.string()
		.nullable()
		.test(
			"test-ssn",
			t("personalInfos.errors.socialSecurityNumber"),
			isValidSSSN
		),
};

const ContactInformationsValidationSchema = {
	phoneNumber: Yup.string()
		.nullable()
		.test("phone", t("registerPhone.error.shouldBePhone"), (value) => {
			if (!value) return true;
			return PHONE_REGEX.test(value);
		}),
	email: Yup.string()
		.nullable()
		.test("email", t("registerEmail.error.shouldBeMail"), (value) => {
			if (!value) return true;
			return EMAIL_REGEX.test(value);
		}),
};

const AddressInformationsValidationSchema = {
	addressStreet: Yup.string().nullable(),

	addressPostalCode: Yup.string()
		.max(5, t("personalInfos.errors.notValidZipcode"))
		.min(5, t("personalInfos.errors.notValidZipcode")),
	addressCountry: Yup.object().shape({
		name: Yup.string(),
		id: Yup.string(),
	}),
	mainLocation: Yup.object()
		.shape({
			id: Yup.string(),
			name: Yup.string(),
			postalCode: Yup.string(),
		})
		.nullable(),
};

const FamilySituationsValidationSchema = {
	familySituation: Yup.string().nullable(),
	childrenQty: Yup.number().nullable(),
};

export const PersonalInformationsFormValidationSchema = () => {
	return Yup.object().shape({
		...IdentityInformationsValidationSchema,
		...SocialSecurityValidationSchema,
		...ContactInformationsValidationSchema,
		...AddressInformationsValidationSchema,
		...FamilySituationsValidationSchema,
	});
};
