import React from "react";
import {
	Button,
	Title,
	InfoMessage,
	Picto,
	ModalV2,
} from "@zolteam/react-ras-library";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { IQuery } from "../../../services/api";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import { promiseToast } from "../../../toastify/toastify";
import { Trans } from "react-i18next";
import Loader from "../../atoms/Loader/Loader";
import SVG from "../../atoms/SVG/SVG";
import DocPicto from "../../../assets/illustrations/cvs.svg";
import {
	DocumentTile,
	EmptyDocumentTile,
} from "../../molecules/DocumentTiles/DocumentTiles";
import { IAddedDoc } from "../../organisms/AddDocModal/AddDocValidationSchema";
import moment from "moment";
import DocumentsService from "../../../services/DocumentsService";
import { IDocType } from "../ProDocsDrawer/ProDocsDrawer";
import ConfirmModal from "../../molecules/ConfirmModal/ConfirmModal";
import PDFViewer from "../../molecules/PDFViewer/PDFViewer";
import ImageViewer from "../../molecules/ImageViewer/ImageViewer";
import Drawer from "../../molecules/Drawer/Drawer";

export interface IDocument {
	id?: number;
	uuid: string;
	name: string;
	commonDocumentName?: string;
	folder: string;
	link: string;
	files?: any[];
}

interface IDocsLayoutProps {
	title?: string | false; // title of the page
	addTitle?: string; // title of the add button
	editTitle?: string; // title of the edit button
	addModal?: React.FC<any>; // modal to add a document
	query: IQuery;
	onUpload: (files: any) => Promise<any>;
	onRemove: (uuid: string) => Promise<any>;
	maxFiles?: number;
	noDataMessage?: string | React.ReactNode;
	noDataComponent?: React.ReactNode;
	acceptedFilesType?: string;
	tileTitle?: string | ((doc: IDocument) => string);
	tileComponent?: React.ReactNode;
	isPersonalDoc?: boolean;
	type?: IDocType;
	editable?: boolean;
}

interface ICurrentAddedDoc extends IDocument, IAddedDoc {}

interface ICurrentDoc {
	document: IDocument | ICurrentAddedDoc | null;
	currentAttachment: any;
	initialAttachmentsCount?: number;
}

const DocsLayout: React.FC<IDocsLayoutProps> = ({
	title,
	addTitle = t("docs.uploadBtn"),
	editTitle = t("docs.editBtn"),
	addModal,
	query = {
		key: ["documents"],
		fn: () => Promise.resolve(),
		opts: {},
	},
	acceptedFilesType,
	onUpload,
	onRemove,
	maxFiles = -1,
	noDataComponent,
	noDataMessage,
	tileTitle,
	tileComponent,
	isPersonalDoc = false,
	editable = true,
	type,
}) => {
	const [CurrentDoc, setCurrentDoc] = React.useState<ICurrentDoc | null>({
		document: null,
		currentAttachment: null,
		initialAttachmentsCount: 0,
	});

	const [IsDeleting, setIsDeleting] = React.useState<IDocument | undefined>(
		undefined
	);
	const [IsUploading, setIsUploading] = React.useState(false);
	const [IsAddModalOpen, setIsAddModalOpen] = React.useState(false);

	const { state } = useLocation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	// take the result of the query and add the elem to an array with 3 elem max
	const genElems = (data: IDocument[]) => {
		if (!data) return [];
		const elems: any = [];
		if (maxFiles < 0) return [...data, undefined];

		for (let i = 0; i < maxFiles; i++) {
			if (data[i]) elems.push(data[i]);
			else elems.push(undefined);
		}
		return elems;
	};

	const handleSelectDoc = (doc: ICurrentAddedDoc) => {
		if (editable) setIsAddModalOpen(true);
		setCurrentDoc({
			...CurrentDoc,
			initialAttachmentsCount: doc.files?.length ?? 0,
			document: {
				...doc,
				effectiveDate: doc.effectiveDate
					? moment(doc.effectiveDate).format(t("dates.format"))
					: "",
				expirationDate: doc.expirationDate
					? moment(doc.expirationDate).format(t("dates.format"))
					: "",
			},
			currentAttachment: null,
		});
	};

	const handleUploadError = (e) => {
		toast.dismiss("compressing");
		toast.dismiss("uploadDoc");
		toast.error(t("docs.uploadError"), {
			toastId: "uploadDoc-error",
		});
		setIsUploading(false);
		queryClient.resetQueries(query.key ?? ["docs"]);
		return e;
	};

	const handleAddDoc = (
		files: FileList,
		onCompress?: (data: { progress: number }) => void
	) => {
		let filesList: any = [];
		const file = files[0];

		setIsUploading(true);

		let compressProm = new Promise((resolve, reject) => {
			if (file.type.includes("image")) {
				const options = {
					maxSizeMB: 5,
					maxWidthOrHeight: 1920,
					onProgress: (progress: number) => {
						if (onCompress)
							onCompress({
								progress,
							});
						else
							toast.update("compressing", {
								render: t("compressor.progress", {
									percent: progress,
								}),
								type: "info",
							});
					},
				};

				const compressProm = imageCompression(file, options).then(
					(compressedFile) => {
						filesList.push({
							fileName: compressedFile.name,
							type: compressedFile.type,
							size: compressedFile.size,
							file: compressedFile,
						});
						resolve(true);
						return compressedFile;
					},
					(e) => {
						toast.error(t("compressor.compressingError"), {
							toastId: "compressing-Error",
						});
						reject(handleUploadError(e));
						return e;
					}
				);
				if (!onCompress)
					promiseToast(
						compressProm,
						{
							pending: t("compressor.progress"),
							success: t("compressor.succeeded"),
						},
						{ toastId: "compressing" }
					);
			} else {
				filesList.push({
					fileName: file.name,
					type: file.type,
					size: file.size,
					file: file,
				});
				return resolve(true);
			}
		});

		let prom = compressProm.then(
			() => {
				let sendProm = onUpload(filesList).then(
					() => {
						setIsUploading(false);
						queryClient.refetchQueries(query.key ?? ["documents"]);
						if (state?.redirectUrl) navigate(state.redirectUrl);
					},
					(e) => {
						return handleUploadError(e);
					}
				);

				toast.dismiss("compressing");

				promiseToast(
					sendProm,
					{
						success: t("docs.uploadSuccess").toString(),
						error: t("docs.uploadError").toString(),
					},
					{
						toastId: "uploadDoc",
					}
				);
				return sendProm;
			},
			(e) => {
				return handleUploadError(e);
			}
		);
		return prom;
	};

	const handleDeleteDoc = (doc: any | undefined) => {
		if (!doc) return;
		let prom = onRemove(doc).then(() => {
			queryClient.resetQueries(query.key ?? ["documents"]);
		});

		return prom;
	};

	const genPreview: any = (doc: IDocument, onClose: () => {}) => {
		if (!doc)
			return (
				<PDFViewer
					onBack={() => {
						setCurrentDoc({
							...CurrentDoc,
							document: CurrentDoc?.currentAttachment
								? CurrentDoc?.document
								: null,
							currentAttachment: null,
						});
					}}
					file="file_error"
				/>
			);

		let docUsed = doc?.files ? doc.files[0] : doc;

		if (docUsed?.name.match(/^.*\.(jpeg|jpg|png)$/)) {
			return (
				<ImageViewer
					key={docUsed.uuid}
					file={docUsed.link}
					onBack={onClose}
					downloadTitle={docUsed.name}
				/>
			);
		} else if (docUsed?.name.match(/^.*\.(pdf|docx)$/)) {
			return (
				<PDFViewer
					key={docUsed.uuid}
					file={docUsed.link}
					onBack={onClose}
				/>
			);
		}
	};

	const shouldShowViewer = () => {
		if (editable) return false;
		if (!CurrentDoc?.document) return false;
		if (!CurrentDoc?.document?.files) return true;
		if (
			(CurrentDoc?.document?.files?.length === 1 &&
				CurrentDoc?.initialAttachmentsCount === 1) ||
			CurrentDoc?.currentAttachment
		)
			return true;
		return false;
	};

	const { isLoading, data, error } = useQuery(query.key, query.fn, {
		...query.opts,
		onSettled: (data) => {
			if (!data?.length) return data;
		},
	});

	if (error)
		return (
			<div>
				{title !== false && (
					<Title tag="h1">{title ?? t("docs.title")}</Title>
				)}
				<div className="flex flex-col items-center justify-center h-full">
					<InfoMessage color="error" withIcon>
						{t("docs.loadingError")}
					</InfoMessage>
				</div>
			</div>
		);

	const DocsElems = genElems(data);
	const count = data?.length ?? 0;
	const AddModal = addModal;

	const genNoDataComponent = () => {
		if (noDataComponent) {
			if (noDataComponent === "empty-tile")
				return (
					<EmptyDocumentTile
						className="mt-xl"
						text={addTitle}
						onClick={
							addModal ? () => setIsAddModalOpen(true) : undefined
						}
						onFileReaded={handleAddDoc}
						disabled={IsUploading}
						accept={acceptedFilesType}
					/>
				);
			return noDataComponent;
		}

		return (
			<div className="flex flex-col items-center mt-xl">
				<p className="flex items-center p-2 leading-4 gap-s rounded-4 bg-warning-100 text-warning-600 text-paragraph-02">
					<Picto icon={"ringingBell"} className="w-6" />
					{noDataMessage ?? <Trans i18nKey="profilePro.cv.empty" />}
				</p>
				<SVG src={DocPicto} />
				<AddDocButton
					onFileReaded={handleAddDoc}
					text={addTitle}
					onClick={
						addModal ? () => setIsAddModalOpen(true) : undefined
					}
				/>
			</div>
		);
	};

	const TileComponent: any = tileComponent ?? DocumentTile;

	return (
		<div>
			{title !== false && (
				<Title tag="h1">{title ?? t("profilePro.cv.title")}</Title>
			)}
			{isLoading && <Loader />}
			{!isLoading && !count && genNoDataComponent()}

			{count > 0 && (
				<>
					{maxFiles > 0 && (
						<InfoMessage
							color={count === maxFiles ? "warning" : "primary"}
							withIcon
						>
							{t(
								count < maxFiles
									? "docs.remainingFiles"
									: "docs.maxFiles",
								{
									count: maxFiles - count,
								}
							)}
						</InfoMessage>
					)}
					<div className="flex flex-wrap mt-8 gap-xl">
						{DocsElems?.map((doc: IDocument, index: number) =>
							doc ? (
								<TileComponent
									key={doc?.uuid ?? index}
									document={doc}
									onDelete={() => setIsDeleting(doc)}
									customHandleDelete={() =>
										handleDeleteDoc(doc)
									}
									onClick={() =>
										handleSelectDoc(doc as ICurrentAddedDoc)
									}
									disabled={
										IsUploading ||
										(!!IsDeleting?.uuid?.length &&
											IsDeleting?.uuid === doc?.uuid)
									}
									title={tileTitle}
								/>
							) : (
								<AddDocTile
									isLoading={isLoading}
									text={addTitle}
									key={index}
									onFileReaded={handleAddDoc}
									disabled={IsUploading}
									onClick={
										addModal
											? () => setIsAddModalOpen(true)
											: undefined
									}
									accept={acceptedFilesType}
									existingDocs={data}
									isPersonalDoc={isPersonalDoc}
									type={type}
								/>
							)
						)}
					</div>
				</>
			)}

			<Drawer
				isOpen={shouldShowViewer()}
				navigation={false}
				onClose={() =>
					setCurrentDoc({
						...CurrentDoc,
						document: CurrentDoc?.currentAttachment
							? CurrentDoc?.document
							: null,
						currentAttachment: null,
					})
				}
			>
				{({ onClose }) => {
					return genPreview(
						CurrentDoc?.currentAttachment ?? CurrentDoc?.document,
						onClose
					);
				}}
			</Drawer>
			{AddModal && (
				<ModalV2
					title={
						CurrentDoc?.document && editTitle ? editTitle : addTitle
					}
					isDisplayed={IsAddModalOpen}
					onClose={() => {
						setIsAddModalOpen(false);
						setCurrentDoc({
							document: null,
							currentAttachment: null,
						});
					}}
					size="s"
				>
					<AddModal
						initialValues={{
							...(CurrentDoc?.document || {}),
							type: CurrentDoc?.document
								? {
										name: CurrentDoc?.document
											?.commonDocumentName,
										id: CurrentDoc?.document
											?.commonDocumentId,
								  }
								: null,
						}}
						fetchedDatas={data}
						closeModal={() => {
							setCurrentDoc({
								document: null,
								currentAttachment: null,
							});
							setIsAddModalOpen(false);
						}}
					/>
				</ModalV2>
			)}
			<ConfirmModal
				title={t("docs.confirmDeleteTitle")}
				onConfirm={() => handleDeleteDoc(IsDeleting)}
				onClose={() => setIsDeleting(undefined)}
				isOpen={!!IsDeleting}
			>
				<Trans
					i18nKey="docs.confirmDeleteText"
					values={{ name: IsDeleting?.name }}
				/>
			</ConfirmModal>
		</div>
	);
};

interface IAddDocTileProps {
	text: string;
	accept?: string;
	onClick?: () => void;
	disabled?: boolean;
	onFileReaded: (
		files: any,
		onCompress?: (values: { progress: number }) => void
	) => Promise<any>;
	existingDocs?: any[];
	isLoading?: boolean;
	isPersonalDoc?: boolean;
	type?: any;
}

const AddDocTile: React.FC<IAddDocTileProps> = ({
	text,
	onClick,
	accept,
	onFileReaded,
	disabled,
	existingDocs,
	isLoading,
	isPersonalDoc,
	type,
}) => {
	const fetchDocsList = () => {
		if (!type?.id) return Promise.resolve([]);

		const apiRoute = isPersonalDoc
			? DocumentsService.getAllProofsTypesForId
			: DocumentsService.getAllProfessionalCommonDocuments;

		return apiRoute(type?.id).then((res) => {
			const docTypes = res?.commonDocuments;
			if (!docTypes.length) return [];

			const types = docTypes
				.map((docType) => {
					const hasExistingDoc =
						existingDocs?.find(
							(existingDoc) =>
								existingDoc.commonDocumentId === docType.id
						) ?? false;

					return {
						...docType,
						label: docType.name,
						value: docType.id,
						hasExistingDoc: hasExistingDoc,
					};
				})
				.filter((a) => a && !a.hasExistingDoc)
				.sort((a, b) => a.label.localeCompare(b.label));

			return types;
		});
	};

	const { isLoading: isLoadingDocs, data } = useQuery(
		["docsList", type?.id],
		fetchDocsList,
		{
			retry: 0,
		}
	);

	if (type?.id && (isLoading || (!isLoadingDocs && !data?.length))) return;

	return (
		<EmptyDocumentTile
			text={text}
			onFileReaded={onFileReaded}
			disabled={disabled || isLoadingDocs}
			onClick={onClick}
			accept={accept}
			isLoading={isLoadingDocs}
		/>
	);
};

/* Add Doc Button */

export interface IAddDocButton {
	text?: string;
	onFileReaded: (
		files: any,
		onCompress?: (values: { progress: number }) => void
	) => Promise<any>;
	disabled?: boolean;
	onClick?: () => void;
}

export const AddDocButton: React.FC<IAddDocButton> = ({
	text = t("docs.uploadBtn"),
	onFileReaded,
	onClick,
}) => {
	return (
		<Button
			type="button"
			color="primary"
			className="relative overflow-hidden"
			onClick={onClick ? onClick : undefined}
		>
			<span className="!cursor-pointer">{text}</span>
			{!onClick && (
				<input
					type="file"
					className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
					accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png, image/jpg, image/jpeg"
					onChange={(e) => onFileReaded(e.target.files)}
				/>
			)}
		</Button>
	);
};

export default DocsLayout;
