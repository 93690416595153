import { COUNTRY_NAME_FRANCE } from "../constants/personalInformations";
import formatDate from "./formatDate";
import { SSN_REGEX } from "./regex";

export const computePersonalInfosValuesForAPI = (values) => {
	const isCountryFrance =
		values?.addressCountry?.name === COUNTRY_NAME_FRANCE;

	let profileIdentityRequestDTO = {
		sex: values?.sex,
		bornCity: values.bornCity || undefined,
		nationalityId: values.nationality?.id,
		nativeCountryId: values.nativeCountry?.id,
		dateOfBirth: values.formattedDateOfBirth
			? formatDate(values.formattedDateOfBirth)
			: undefined,
	};

	let profileSocialSecurityNumberRequestDTO = {
		ssn: values.ssn,
	};

	let profileFamilySituationRequestDTO = {
		familySituation: values.familySituationOption
			? values.familySituationOption.id
			: values.familySituation,
		childrenQty: parseInt(values.childrenQty, 10),
	};

	let profilePostalAddressRequestDTO = {
		addressStreet: values?.addressStreet || "",
		addressBuilding: values?.addressBuilding || "",
		addressCountryId: values?.addressCountry?.id || null,
		mainLocation: !isCountryFrance ? null : values?.mainLocation?.id,

		// if country is France, city & postalCode are not required because it is provided by the mainLocation
		addressPostalCode:
			values?.addressPostalCode && !isCountryFrance
				? parseInt(values?.addressPostalCode, 10)
				: null,
		addressCity: !isCountryFrance ? values?.addressCity : null,
	};

	const payload = {
		profileIdentityRequestDTO,
		profileFamilySituationRequestDTO,
		profilePostalAddressRequestDTO,
	};

	if (values?.ssn) {
		payload["profileSocialSecurityNumberRequestDTO"] =
			profileSocialSecurityNumberRequestDTO;
	}

	return payload;
};

export const isValidSSSN = (value) => {
	if (value) {
		const normalKey = 97 - (parseInt(value.substr(0, 13), 10) % 97);
		return (
			SSN_REGEX?.test(value) &&
			normalKey === parseInt(value.substr(value?.length - 2, 2), 10)
		);
	}
	return true;
};
