import React, { useRef } from "react";
import { Button, SelectAsync } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import Field from "../../components/molecules/Field/Field";
import cn from "../../utils/cn";
import { t } from "i18next";
import ProfileService from "../../services/ProfileService";
import {
	IPersonalInformationsCategory,
	IPersonalInformationsEditValues,
} from "./PersonalInformations";
import { noAccents } from "../../utils/noAccents";
import SessionService from "../../services/SessionService";
import moment from "moment/moment";
import DatePickerField from "../../components/molecules/DatePickerField/DatePickerField";

const MIN_SEARCH_LENGTH = 2;

const IdentityInformations: React.FC<IPersonalInformationsCategory> = ({
	disabled,
}) => {
	const fetchTM = useRef<NodeJS.Timeout>();

	const { values, setValues, setFieldValue, setFieldTouched } =
		useFormikContext<IPersonalInformationsEditValues>();

	const status = SessionService.getUser().twStatus;

	const debouncer = (fn: Function, delay: number) => {
		clearTimeout(fetchTM.current);
		return new Promise((resolve) => {
			fetchTM.current = setTimeout(() => {
				fn().then(resolve, resolve);
			}, delay);
		});
	};

	const loadNationalities = (searchTerm: string) => {
		if (searchTerm?.length < MIN_SEARCH_LENGTH) return Promise.resolve([]);
		return ProfileService.getNationalities().then((resp) => {
			// filter items that name contains `inputValue`
			return resp.filter((i) =>
				noAccents(i.name.toLowerCase()).includes(
					noAccents(searchTerm.toLowerCase())
				)
			);
		});
	};

	const loadCountries = (searchTerm: string) => {
		if (searchTerm?.length < MIN_SEARCH_LENGTH) return Promise.resolve([]);
		return ProfileService.getCountries().then((resp) => {
			// filter items that name contains `inputValue`
			return resp.filter((i) =>
				noAccents(i.name.toLowerCase()).includes(
					noAccents(searchTerm.toLowerCase())
				)
			);
		});
	};

	return (
		<div className={cn([disabled && "cursor-not-allowed", "w-full"])}>
			<div className="relative flex flex-col items-start justify-start w-full gap-4">
				<div
					className={cn([
						"flex flex-col gap-2 w-full",
						disabled && "pointer-events-none opacity-70",
					])}
				>
					<b className="">{t("personalInfos.identity.title")}</b>
					<div className="flex gap-2">
						<Button
							className={cn([
								"!border-solid !border-2",
								values.sex === "female" &&
									"!border-primary-500",
							])}
							outline={values.sex !== "female"}
							color={values.sex === "female" ? "primary" : "grey"}
							type="button"
							onClick={() => {
								setFieldValue("sex", "female");
								setFieldTouched("sex");
							}}
						>
							{t("personalInfos.identity.women")}
						</Button>
						<Button
							className={cn([
								"!border-solid !border-2",
								values.sex === "male" && "!border-primary-500",
							])}
							outline={values.sex !== "male"}
							color={values.sex === "male" ? "primary" : "grey"}
							type="button"
							onClick={() => {
								setFieldValue("sex", "male");
								setFieldTouched("sex");
							}}
						>
							{t("personalInfos.identity.men")}
						</Button>
					</div>
					<div className="flex flex-row flex-wrap gap-2 [&>div]:w-full lg:[&>div]:w-[calc(100%/2-0.4rem)] xl:[&>div]:w-[calc(100%/3-0.4rem)]">
						<Field
							label={t("global.firstName")}
							name="firstName"
							type="text"
							disabled
						/>
						<Field
							label={t("global.lastName")}
							name="lastName"
							type="text"
							disabled
						/>
						<div>
							<DatePickerField
								label={t("personalInfos.identity.birthDate")}
								name={"formattedDateOfBirth"}
								onChange={(val) => {
									setValues({
										...values,
										dateOfBirth: val,
										formattedDateOfBirth: moment(
											val
										).format(t("dates.format")),
									});
									setFieldTouched("dateOfBirth");
								}}
								disabled={status !== "waiting"}
								value={values.dateOfBirth}
								pickerProps={{
									minDate: moment()
										.subtract(70, "years")
										.toDate(),
									maxDate: moment()
										.subtract(18, "years")
										.toDate(),
								}}
							/>
						</div>
						<Field
							label={t("personalInfos.identity.birthTown")}
							type="text"
							name="bornCity"
							maxLength={30}
						/>
						<SelectAsync
							defaultOptions
							loadOptions={(inputValue) =>
								debouncer(() => loadCountries(inputValue), 500)
							}
							label={t("personalInfos.identity.nativeCountry")}
							value={values.nativeCountry}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							searchIcon={true}
							isSearchable={true}
							onChange={(value) => {
								setFieldValue("nativeCountry", value);
							}}
							noOptionsMessage={({ inputValue }) => {
								if (inputValue?.length < MIN_SEARCH_LENGTH)
									return t("search.searchMinLength", {
										min: MIN_SEARCH_LENGTH,
									});
								return t("search.noResult", {
									search: inputValue,
								});
							}}
							loadingMessage={() => t("search.loading")}
							name="nativeCountry"
						/>
						<SelectAsync
							defaultOptions
							loadOptions={(inputValue) =>
								debouncer(
									() => loadNationalities(inputValue),
									500
								)
							}
							label={t("personalInfos.identity.nationality")}
							value={values.nationality}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							searchIcon={true}
							isSearchable={true}
							onChange={(value) => {
								setFieldValue("nationality", value);
							}}
							noOptionsMessage={({ inputValue }) => {
								if (inputValue?.length < MIN_SEARCH_LENGTH)
									return t("search.searchMinLength", {
										min: MIN_SEARCH_LENGTH,
									});
								return t("search.noResult", {
									search: inputValue,
								});
							}}
							loadingMessage={() => t("search.loading")}
							name="nationality"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IdentityInformations;
